<template>
   <div class="checkHomePage">
     <div class="bos">

<!--       <UPfile :type="'CTM'"></UPfile>-->

       <ul class="content">
         <li :class="qx.up_predeal?'':'noqx'" @click="go('/home/acceptsHome', 1, qx.up_predeal)">
           <div>
             <img src="../assets/img/inquiry.png" alt="">
           </div>
           <p>接诊预诊</p>
         </li>
         <li :class="qx.up_peculiar?'':'noqx'" @click="go('/home/examinationEyesHome', 2, qx.up_peculiar)">
           <div>
             <img src="../assets/img/eye_check.png" alt="">
           </div>
           <p>特检科</p>
         </li>
         <li :class="qx.up_doctor?'':'noqx'" @click="go('/home/expertClinicHome', 3, qx.up_doctor)">
           <div>
             <img src="../assets/img/conrea/contact_inquiry.png" alt="">
           </div>
           <p>专家诊室</p>
         </li>
<!--RefractiveDepartmentHome   corneaContactHome-->
         <li :class="qx.up_refraction?'':'noqx'" @click="go('/RefractiveDepartmentHome', 4, qx.up_refraction)">
           <div>
             <img src="../assets/img/bigData.png" alt="">
           </div>
           <p>屈光科</p>
         </li>

         <li :class="qx.up_optometry?'':'noqx'" @click="go('/optometryHome', 5, qx.up_optometry)">
           <div>
             <img src="../assets/img/recipe_demo.png" alt="">
           </div>
           <p>视光科</p>
         </li>
       </ul>
       <ul class="content" style="padding-bottom: 0;">

         <li :class="qx.up_squintamblyopia?'':'noqx'" @click="go('/training/onlineTrainingHome', 8, qx.up_squintamblyopia)">
           <div>
             <img src="../assets/img/3d_demo.png" alt="">
           </div>
           <p>斜弱视</p>
         </li>

         <li :class="qx.up_referral?'':'noqx'" @click="go('/eyeDiseaseHome', 6, qx.up_referral)">
           <div>
             <img src="../assets/img/tradeCenter.png" alt="">
           </div>
           <p>眼病综合科</p>
         </li>

         <li :class="qx.up_balance?'':'noqx'" @click="go('/settlementCenter', 7, qx.up_balance)">
           <div>
             <img src="../assets/img/cgcc.png" alt="">
           </div>
           <p>结算中心</p>
         </li>


         <li :class="qx.up_report?'':'noqx'" @click="go('/CRMcontent', 9, qx.up_report)">
           <div>
             <img src="../assets/img/report.png" alt="">
           </div>
           <p>健康报告CRM中心</p>
         </li>
         <li @click="open(qx.up_aftersales)" :class="qx.up_aftersales?'':'noqx'">
           <div>
             <img src="../assets/img/mzyygl.png" alt="">
           </div>
           <p>售后管理中心</p>
         </li>
       </ul>
     </div>
     <el-dialog
         title="请选择门店"
         :visible.sync="showShop"
         :close-on-click-modal="false"
         width="30%"
         :show-close="false"
         center>
       <div class="mdbox">
          <p v-for="(item, idx) in shopList" :class="shopIdx==idx?'xz':''" @click="xz(idx)">{{item.ShopName}}</p>
       </div>
       <span slot="footer" class="dialog-footer">
         <el-button type="primary" @click="qr">确 定</el-button>
       </span>
     </el-dialog>
   </div>
</template>

<script>
import fn from '@/publicFn/baseFn'
import pf from '@/publicFn/baseFn'
    export default {
      name: "checkHomePage",
      data() {
        return {
          showShop: false,
          shopList: [],
          shopIdx: 0,
          qx: {}
        }
      },
      created() {
        // document.onkeydown = (e) => {
        //   console.log(e)
        // }




        let qxlist = this.$store.state.users.user.UserPosition.split(','),qxitem={}
        if (qxlist && qxlist.length) {
          for (let i = 0; i < qxlist.length; i++) {
            qxitem[qxlist[i]] = qxlist[i]
          }
        }

        // console.log(this.$store.state.bases.qx.uf_rgpolution)

        if (!this.$store.state.bases.qx) {
          let xqx = this.$store.state.users.user.UserFunction.split(','),xqxitem = {}
          if (xqx && xqx.length) {
            for (let i = 0; i < xqx.length; i++) {
              xqxitem[xqx[i]] = xqx[i]
            }
          }
          this.$store.commit('bases/upqx', xqxitem)
        }

        this.qx = qxitem

        // console.log(this.$store.state.users.user.ListUserFunction)
        // console.log(this.$store.state.users.user)
        if (!this.$store.state.bases.shop && localStorage.getItem('shops') && !this.$store.state.isShow.ishmd) {
          this.$store.commit('bases/UpShop', JSON.parse(localStorage.getItem('shops')))
        }
        if (!this.$store.state.bases.shop) {
          this.showShop = true
          this._api.Company.getShop(this.$store.state.users.user.Company.CompanyId)
          .then(res => {
            if (res.GetListResult) {
              this.shopList = res.GetListResult
            }
          })
        }

        if (this.$store.state.physicianVisits.xzConsulting && !this.$store.state.users.employees) {
           this.$router.push({
             path: '/employeesLanding'
           })
        }

        if (this.$store.state.users.employees.ExpertId && !this.$store.state.expertClinic.qmUrl) {
          this._api.expertClinic.getExSignature()
                  .then(res => {
                    // console.log(res)
                    if (res.GetResult) {
                      this.$store.commit("expertClinic/upQmUrl", res.GetResult.UFPath)
                    }
                  })
        }
      },
      methods: {
        go(url, idx, str) {
          // console.log(str)
          if (!this.qx[str]) {
            return false;
          }
          if (!this.$store.state.users.CsUser && !this.$store.state.physicianVisits.xzConsulting) {
            this.$router.push({path: '/csLanding'})
            return
          }
          this.$store.commit('bases/upBottomIdx', idx)
          this.$router.push({path: url})
        },
        qr() {
          localStorage.setItem('shops', JSON.stringify(this.shopList[this.shopIdx]))
          this.$store.commit('bases/UpShop', this.shopList[this.shopIdx])
          if (this.shopList[this.shopIdx].ShopLogoURL) {
            fn.SetCookie('GsData', this.shopList[this.shopIdx].ShopLogoURL)
          }
          this.showShop = false
        },
        xz(idx) {
          this.shopIdx = idx
        },
        open(qx) {
          if (!qx) return false
          this.$router.push({path: '/AfterSaleManagementHome'})
        }
      },
      components: {
        UPfile: () => import('../components/CommonComponents/upFile')
      }
    }
</script>

<style scoped lang="scss">
    .checkHomePage {
       width: 100vw;
      height: 76vh;
      display: flex;
      align-items: center;
      overflow: hidden;
      .bos {
        //height: 5.5rem;
      }
    }
    .content {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          cursor: pointer;
          padding: .3rem .5rem;
           img {
             display: block;
             width: 170px;
             padding-bottom: .2rem;
             @media (max-width: 1200px) {
               width: 140px;
             }
           }
        }
    }

    .mdbox {
      width: 100%;
      max-height: 40vh;
      overflow-y: auto;
      text-align: center;
      p {
        cursor: pointer;
        padding: 0.05rem 0;
        border-radius: 5px;
        &:hover{
          background: #E5E5E5;
        }
      }
      .xz {background: #E5E5E5;}
    }

    .noqx {
      cursor: not-allowed !important;
    }
</style>
